export default {
  en: {
    keywords: 'Restaurant Loyalty Programs',
    pageTitle: 'Best Restaurant Loyalty Program Software | Chowbus POS',
    metaDescription: `Boost Your Business with Chowbus' Restaurant Loyalty Program! Ditch paper cards, drive 25% more visits, and engage customers effortlessly. Get started now!`,
  },
  zh: {
    keywords: '餐厅会员系统 | Chowbus POS | Restaurant Loyalty Programs',
    pageTitle: '餐饮会员系统 | Chowbus POS',
    metaDescription: `利用Chowbus餐馆电脑的会员系统加快您餐厅的增长！会员充值、菜品会员价、会员积分、跨店会员、促销活动、礼品卡等样样具备，帮助您提升25+%营业额！`,
  },
};
