export default {
  en: {
    keywords: 'Boba Tea POS System',
    pageTitle: 'POS System for Bubble Tea Shops | Chowbus POS',
    metaDescription: `Elevate your restaurant's efficiency and profits with Chowbus Tablet POS! Contactless, cost-effective, and enhanced guest experiences await. Upgrade today!`,
  },
  zh: {
    keywords: '奶茶店POS解决方案 | Chowbus POS | Boba Tea POS System',
    pageTitle: 'Chowbus POS系统: 美国奶茶店餐厅电脑首选，专为奶茶店设计的点餐系统',
    metaDescription: `使用Chowbus的奶茶店餐馆电脑解决方案全面升级您的餐厅！您可以即刻升级自助点餐系统、简化运营流程、增加收益并提升顾客体验！Machi Machi、朝茶、愿茶、老虎堂等北美著名奶茶店都在用的餐馆电脑。`,
  },
};
