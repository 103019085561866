export default {
  en: {
    keywords: 'Restaurant POS Integration',
    pageTitle: 'Third Party Delivery Integration | Chowbus POS',
    metaDescription: `Revolutionize your restaurant with Chowbus POS - the all-in-one restaurant POS system. Save $8,000/mo in labor costs and boost growth by 25%. See how it works!`,
  },
  zh: {
    keywords: '餐厅POS系统集成服务 | Chowbus POS | Restaurant POS Integration',
    pageTitle: '第三方外卖平台集成，支持中餐外卖平台',
    metaDescription: `使用Chowbus餐馆电脑简化您的集成流程。Chowbus POS可以集成近50家外卖平台。外卖订单直接导入POS系统，无需多个硬件设备接单，无需手动输入外卖订单，提升订单的准确性。赶快试用吧！`,
  },
};
