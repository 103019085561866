export default {
  en: {
    keywords: 'Restaurant Reservation System',
    pageTitle: 'Restaurant Reservation System & Waitlist | Chowbus POS',
    metaDescription: `Revolutionize your restaurant with Chowbus POS - the all-in-one restaurant POS system. Save $8,000/mo in labor costs and boost growth by 25%. See how it works!`,
  },
  zh: {
    keywords: '餐厅预约等位系统 | Chowbus POS | Restaurant Reservation System',
    pageTitle: '餐馆预约服务和座位 | Chowbus POS',
    metaDescription: `Chowbus餐馆电脑提供餐馆预约服务和座位管理系统，帮助您简化入座流程、缩短等位时间、挽回潜在流失客人。预约和等位渠道覆盖餐厅店里、谷歌地图、餐厅官网等线上线下多个渠道。`,
  },
};
