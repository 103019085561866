import { default as homepage } from './homepage';
import { default as pricing } from './pricing';
import { default as whyChowbusPos } from './why-chowbus-pos';
import { default as pointOfSable } from './point-of-sales';
import { default as handheld } from './handheld-pos';
import { default as tableordering } from './tablet-ordering';
import { default as qrcode } from './qrcode-ordering';
import { default as kiosk } from './kiosk';
import { default as loyalty } from './loyalty';
import { default as onlineordering } from './online-ordering';
import { default as thirdpartyIntegration } from './third-party-integrations';
import { default as waitlist } from './waitlist';
import { default as reservation } from './reservation';
import { default as rutechrobots } from './rutech-robots';
import { default as fullservice } from './full-service';
import { default as quickservce } from './quick-service';
import { default as hotpot } from './hot-pot';
import { default as koreanbbq } from './korean-bbq';
import { default as bobaTeaDessert } from './boba-tea-dessert';
import { default as japaneseRamen } from './japanese-ramen';
import { default as dimSumPos } from './dim-sum';
import { default as chineseRestaurant } from './chinese-restaurant';
import { default as pizza } from './pizza';
import { default as cafe } from './cafe';
import { default as bar } from './bars';
export default {
  'pos-web-site-home-page-enh': {
    ...homepage,
  },
  'point-of-sale': {
    ...pointOfSable,
  },

  'pos-handheld': {
    ...handheld,
  },
  'pos-tablet-ordering': {
    ...tableordering,
  },
  'pos-qrcode-ordering': {
    ...qrcode,
  },

  'pos-kiosk-ordering': {
    ...kiosk,
  },

  'pos-loyalty': {
    ...loyalty,
  },
  'pos-online-ordering': {
    ...onlineordering,
  },
  'pos-thirdparty-integration': {
    ...thirdpartyIntegration,
  },

  'pos-waitlist': {
    ...waitlist,
  },
  'pos-reservation': {
    ...reservation,
  },

  'pos-rutech-robot': {
    ...rutechrobots,
  },

  'pos-full-service': {
    ...fullservice,
  },
  'pos-quick-service': {
    ...quickservce,
  },

  'pos-hot-pot': {
    ...hotpot,
  },
  'pos-korean-bbq': {
    ...koreanbbq,
  },

  'pos-boba-tea-dessert': {
    ...bobaTeaDessert,
  },

  'pos-japanese-ramen': {
    ...japaneseRamen,
  },
  'pos-pizza': {
    ...pizza,
  },

  'pos-cafes': {
    ...cafe,
  },
  'pos-bars': {
    ...bar,
  },
  'pos-dim-sum': {
    ...dimSumPos,
  },
  'pos-pricing': {
    ...pricing,
  },
  'pos-why-chowbus-pos': {
    ...whyChowbusPos,
  },

  default: {
    ...homepage,
  },
};
