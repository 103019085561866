export default {
  en: {
    keywords: 'Restaurant POS Software',
    pageTitle: 'Point-of-Sale (POS) Systems | Chowbus POS',
    metaDescription:
      'Elevate your restaurant operations with Restaurant POS Software! Manage orders, payments, and loyalty effortlessly with Chowbus. Streamline your business today.',
  },
  zh: {
    keywords: 'POS餐饮管理系统 | Chowbus POS',
    pageTitle: '借助Chowbus先进且易用的智能餐饮管理系统降本增效',
    metaDescription:
      '使用餐厅POS系统提升餐厅的运营效率！您可以使用Chowbus轻松管理订单、支付和会员。快使用Chowbus餐馆电脑来精简餐厅的运营流程吧！',
  },
};
