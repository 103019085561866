export default {
  en: {
    keywords: 'RuTech Robot',
    pageTitle: 'RuTech Robot | Chowbus POS',
    metaDescription: `Elevate your restaurant's efficiency and profits with Chowbus Tablet POS! Contactless, cost-effective, and enhanced guest experiences await. Upgrade today!`,
  },
  zh: {
    keywords: 'RuTech送餐机器人 | Chowbus POS | RuTech Robot',
    pageTitle: 'RuTech餐厅机器人 | Chowbus POS',
    metaDescription: `使用RuTech餐厅机器人体验未来的服务方式！它可以帮助餐厅、酒店、办公室等场所提升运营效率并改善客户关系。赶快试用吧！`,
  },
};
