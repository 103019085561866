export default {
  en: {
    keywords: 'QR Code Menu',
    pageTitle: 'QR Code Menu for Restaurants | Digital Menu',
    metaDescription: `Boost Your Business with Chowbus QR Code Menu: Empower guests & staff with efficient contactless ordering. Save time, cut costs, and enhance dining experiences.`,
  },
  zh: {
    keywords: '餐厅二维码菜单 | 电子菜单 | QR Code Menu',
    pageTitle: '二维码点餐系统 | 二维码菜单',
    metaDescription: `使用Chowbus扫码点餐解锁无接触的点餐体验。二维码菜单可以帮助餐厅节约点餐时间、节省人力成本和提升用餐体验。`,
  },
};
