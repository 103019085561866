export default {
  en: {
    keywords: 'Japanese Restaurant POS System',
    pageTitle: 'Japanese Restaurant POS System | Sushi & Ramen',
    metaDescription: `Elevate your restaurant's efficiency and profits with Chowbus Tablet POS! Contactless, cost-effective, and enhanced guest experiences await. Upgrade today!`,
  },
  zh: {
    keywords: '日式餐厅POS解决方案 | Chowbus POS | Japanese Restaurant POS System',
    pageTitle: 'Chowbus POS系统: 美国日料店餐厅电脑首选，专为日式餐厅设计的点餐系统',
    metaDescription: `使用Chowbus的日料店餐馆电脑解决方案全面升级您的餐厅！您可以即刻升级二维码菜单系统、简化运营流程、增加收益并提升顾客体验！九汤屋、深夜食堂、Fancy sushi等北美著名日料店都在用的餐馆电脑。`,
  },
};
