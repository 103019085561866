export default {
  en: {
    keywords: 'POS System | Pricing | Chowbus POS',
    pageTitle: 'See Pricing | Chowbus POS',
    metaDescription: `Chowbus offers flexible pricing plans for every restaurant type, size!`,
  },
  zh: {
    keywords: 'See Pricing | Chowbus POS',
    pageTitle: '灵活富有弹性系统价格 | Chowbus POS',
    metaDescription: `Chowbus为每个餐厅类型，大小提供灵活的定价计划!`,
  },
};
