export default {
  en: {
    keywords: 'Quick Service POS System',
    pageTitle: 'Quick Service POS System | QSR POS',
    metaDescription: `Elevate your restaurant's efficiency and profits with Chowbus Tablet POS! Contactless, cost-effective, and enhanced guest experiences await. Upgrade today!`,
  },
  zh: {
    keywords: '快餐店POS解决方案 | Chowbus POS | Quick Service POS System | QSR POS',
    pageTitle: '快餐店POS解决方案 | Chowbus POS',
    metaDescription: `使用Chowbus的快餐店餐馆电脑解决方案升级您的快餐店！您可以即刻升级点餐系统、加快服务速度、缩短顾客等餐时间并增加收入！`,
  },
};
