import React, { useRef, useEffect, useMemo } from 'react';
import PageSEOMetaData from 'src/consts/poswebsite_content/seo-meta-data';
import { LOCALE_EN } from 'src/consts';
import useLocale from './useLocale';

export default function useSEOMeta({ pageId }) {
  const locale = useLocale();
  const { keywords, pageTitle, metaDescription } = useMemo(() => {
    let HomePageSEOMetaData = PageSEOMetaData[pageId] || PageSEOMetaData['default'];
    let { keywords, pageTitle, metaDescription } = HomePageSEOMetaData[locale];
    return {
      keywords: keywords || HomePageSEOMetaData[LOCALE_EN].keywords,
      pageTitle: pageTitle || HomePageSEOMetaData[LOCALE_EN].pageTitle,
      metaDescription: metaDescription || HomePageSEOMetaData[LOCALE_EN].metaDescription,
    };
  }, [locale]);

  return {
    keywords,
    pageTitle,
    metaDescription,
  };
}
