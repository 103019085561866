export default {
  en: {
    keywords: 'Korean BBQ POS System',
    pageTitle: 'Korean Restaurant POS System | Chowbus POS',
    metaDescription: `Elevate your restaurant's efficiency and profits with Chowbus Tablet POS! Contactless, cost-effective, and enhanced guest experiences await. Upgrade today!`,
  },
  zh: {
    keywords: '韩国烤肉店POS解决方案 | Chowbus POS | Korean BBQ POS System',
    pageTitle: 'Chowbus POS系统: 美国烤肉店餐厅电脑首选，专为烤肉店设计的点餐系统',
    metaDescription: `使用Chowbus的烤肉店餐馆电脑解决方案全面升级您的餐厅！您可以即刻升级iPad点餐系统、简化运营流程、增加收益并提升顾客体验！Jongro、友情客串、从前的小酒馆等北美著名烤肉店都在用的餐馆电脑。`,
  },
};
