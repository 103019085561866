export default {
  en: {
    keywords: 'Online Ordering POS',
    pageTitle: 'Online Ordering System For Restaurants | Chowbus POS',
    metaDescription: `Boost restaurant profits with Chowbus Online Ordering POS. Direct website orders, white-label branding, and seamless integration – No commissions, only success!`,
  },
  zh: {
    keywords: '餐厅线上点单方案 | Chowbus POS | Restaurant Loyalty Programs',
    pageTitle: '网页支付系统 | Chowbus POS',
    metaDescription: `利用Chowbus线上点餐系统加快您餐厅的增长！客人直接在餐厅官网下单、支付。您不仅能塑造品牌影响力，还能无需支付佣金，在餐馆电脑中统一管理。`,
  },
};
