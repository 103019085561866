export default {
  en: {
    keywords: 'Dim Sum Restaurant POS System',
    pageTitle: 'POS System for your Dim Sum Restaurant | Chowbus POS',
    metaDescription: `Boost efficiency with Chowbus, the best Dim Sum Restaurant POS System. Save costs, enhance service speed, and maximize profits for a seamless dining experience.`,
  },
  zh: {
    keywords: '早茶店/粤菜馆POS解决方案 | Chowbus POS | Dim Sum Restaurant POS System',
    pageTitle: '早茶店/粤菜馆POS | Chowbus POS',
    metaDescription: `使用用Chowbus最好的点心餐厅POS系统提高效率,节省成本，提高服务速度，实现利润最大化，实现无缝用餐体验！`,
  },
};
