export default {
  en: {
    keywords: 'Full Service Restaurant POS System',
    pageTitle: 'Full Service Restaurant POS System | Chowbus POS',
    metaDescription: `Elevate your restaurant's efficiency and profits with Chowbus Tablet POS! Contactless, cost-effective, and enhanced guest experiences await. Upgrade today!`,
  },
  zh: {
    keywords: '堂食餐厅POS解决方案 | Chowbus POS | Full Service Restaurant POS System',
    pageTitle: '堂食餐厅POS解决方案 | Chowbus POS',
    metaDescription: `使用Chowbus的堂食餐厅餐馆电脑解决方案升级您的桌边服务！您可以即刻升级点餐系统、简化运营流程、增加收益并提升顾客体验！`,
  },
};
