export default {
  en: {
    keywords: 'Restaurant Self-Ordering Kiosks | Restaurant Kiosks Software',
    pageTitle: 'Restaurant Self-Ordering Kiosks | Restaurant Kiosk Software',
    metaDescription: `Cutting-edge Kiosk ordering, Tablet ordering, QR code ordering solutions help save HUGE costs!`,
  },
  zh: {
    keywords: '智能自助点餐机 | 餐厅智能点餐方案 | Restaurant Self-Ordering Kiosks | Restaurant Kiosk Software',
    pageTitle: '智能自助点餐机 | 餐厅智能点餐方案',
    metaDescription: `尖端的Kiosk点餐，平板电脑点餐，二维码点餐解决方案帮助节省巨大的成本!`,
  },
};
