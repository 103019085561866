export default {
  en: {
    keywords: 'Cafe POS System',
    pageTitle: 'Bakery & Cafe POS System | Chowbus POS',
    metaDescription: `Enhance your Cafe Operations with our Cafe POS System. Simplify orders, boost efficiency, and delight customers with flexible payment options. Upgrade Today!`,
  },
  zh: {
    keywords: '咖啡店POS解决方案 | Chowbus POS | Cafe POS System',
    pageTitle: 'Chowbus POS系统: 美国咖啡店餐厅电脑首选，专为咖啡店设计的点餐系统',
    metaDescription: `使用Chowbus的咖啡店餐馆电脑解决方案全面升级您的餐厅！您可以即刻升级自助点餐系统、简化运营流程、增加收益并提升顾客体验！许留山、茶汤会等北美著名甜品店都在用的餐馆电脑。`,
  },
};
