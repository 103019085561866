export default {
  en: {
    keywords: 'Tablet POS',
    pageTitle: 'Restaurant Tablet Ordering System | Chowbus POS',
    metaDescription: `Elevate your restaurant's efficiency and profits with Chowbus Tablet POS! Contactless, cost-effective, and enhanced guest experiences await. Upgrade today!`,
  },
  zh: {
    keywords: '餐厅平板POS系统 | Chowbus POS | Tablet POS',
    pageTitle: 'ipad点餐系统 | Chowbus POS',
    metaDescription: `使用Chowbus ipad点餐系统提升您餐厅的运营效率和收益！无接触的ipad点餐系统为顾客提供高清大图和自助点餐体验，还能节省餐馆点餐人工。`,
  },
};
