export default {
  en: {
    keywords: 'Bar POS System',
    pageTitle: 'Bar POS System | Chowbus POS',
    metaDescription: `Enhance your Bars Operations with our Bar POS System. Simplify orders, boost efficiency, and delight customers with flexible payment options. Upgrade Today!`,
  },
  zh: {
    keywords: '酒吧POS解决方案 | Chowbus POS | Bar POS System',
    pageTitle: '酒吧POS解决方案 | Chowbus POS',
    metaDescription: `使用Chowbus的酒吧解决方案来提升您餐厅的运营效率吧！您可以简化订单接单、制作流程，提升运营效率并提供多种支付方式以增加顾客满意度。赶快试试吧！`,
  },
};
