export default {
  en: {
    keywords:
      'Restaurant Reservation System | Chowbus POS | Restaurant Reservation software | Restaurant Reservation App',
    pageTitle: 'Restaurant Reservation System | Chowbus POS',
    metaDescription: `Revolutionize your restaurant with Chowbus POS - the all-in-one restaurant POS system. Save $8,000/mo in labor costs and boost growth by 25%. See how it works!`,
  },
  zh: {
    keywords:
      '餐厅预约等位系统 | Chowbus POS | Restaurant Reservation System | Restaurant Reservation software | Restaurant Reservation App',
    pageTitle: '餐馆预约服务和座位 | Chowbus POS',
    metaDescription: `使用Chowbus餐馆预约服务和座位系统最大化餐厅运营效率。Chowbus预约和等位系统可以帮助您简化入座流程、缩短等位时间、增加顾客参与度！`,
  },
};
