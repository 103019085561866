export default {
  en: {
    keywords: 'POS System | Why Chowbus POS | Chowbus POS',
    pageTitle: 'Why Chowbus POS | Chowbus POS',
    metaDescription: `Wondering why TOP restaurants choose Chowbus? Learn more about us! `,
  },
  zh: {
    keywords: 'POS System | Why Chowbus POS | Chowbus POS',
    pageTitle: '餐馆电脑｜餐馆点餐系统｜专为亚洲餐厅定制',
    metaDescription:
      '使用Chowbus餐馆电脑升级您的餐厅！Chowbus为餐厅提供包含点餐系统在内的一站式解决方案，为您每月节省$8,000的人工成本并提升25%营业额。点击查看详情！',
  },
};
