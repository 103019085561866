export default {
  en: {
    keywords: 'Handheld POS System',
    pageTitle: 'Handheld POS System for Restaurants | Mobile POS',
    metaDescription:
      'Unlock seamless transactions with Chowbus POS Handheld - your ultimate handheld POS system. Portable, integrated, & powerful. The future of payment convenience.',
  },
  zh: {
    keywords: '餐厅手持POS系统 | 移动POS | Handheld POS System',
    pageTitle: '手持POS点餐系统，随时随地都能点餐、管理订单、收款｜移动POS系统',
    metaDescription:
      'Chowbus餐馆电脑的手持POS系统包含点餐系统、支付系统、管理系统，能让您在桌边点餐、下单、管理订单、收款，提升效率。',
  },
};
