export default {
  en: {
    keywords: 'Pizza POS System',
    pageTitle: 'Pizza POS System | Best POS System for Pizza Restaurants',
    metaDescription: `Streamline Pizzeria Operations with Chowbus Pizza POS System. Customize orders, manage deliveries, and boost efficiency for your Pizza business today.`,
  },
  zh: {
    keywords: '披萨店POS解决方案 | Chowbus POS | Pizza POS System',
    pageTitle: '披萨店点餐 | Chowbus POS',
    metaDescription: `使用Chowbus的披萨店餐馆电脑解决方案全面升级您的餐厅！您可以即刻升级披萨店点餐系统、简化运营流程、增加收益并提升顾客体验！`,
  },
};
