export default {
  en: {
    keywords: 'Restaurant POS Systems',
    pageTitle: 'Chowbus: Restaurant POS & Management System',
    metaDescription:
      'Revolutionize your restaurant with Chowbus POS - the all-in-one restaurant POS system. Save $8,000/mo in labor costs and boost growth by 25%. See how it works!',
  },
  zh: {
    keywords: 'Chowbus: POS餐饮管理系统 | Restaurant POS Systems',
    pageTitle: '餐馆电脑｜餐馆点餐系统｜专为亚洲餐厅定制',
    metaDescription:
      '使用Chowbus餐馆电脑升级您的餐厅！Chowbus为餐厅提供包含点餐系统在内的一站式解决方案，为您每月节省$8,000的人工成本并提升25%营业额。点击查看详情！',
  },
};
