export default {
  en: {
    keywords: 'Hot Pot POS System',
    pageTitle: 'Hot Pot Restaurant POS System | Chowbus POS',
    metaDescription: `Elevate your restaurant's efficiency and profits with Chowbus Tablet POS! Contactless, cost-effective, and enhanced guest experiences await. Upgrade today!`,
  },
  zh: {
    keywords: '火锅店POS解决方案 | Chowbus POS | Hot Pot POS System',
    pageTitle: 'Chowbus POS系统: 美国火锅店餐厅电脑首选，专为火锅店设计的点餐系统',
    metaDescription: `使用Chowbus的火锅店餐馆电脑解决方案全面升级您的餐厅！您可以即刻升级iPad点餐系统、简化运营流程、增加收益并提升顾客体验！小龙坎、刘一手、香天下、贰火锅、蜀大侠等北美著名火锅店都在用的餐馆电脑。`,
  },
};
